import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ERROR_MESSAGE_URL_IS_BROKEN } from '../shared/constants/messages';

@Component({
  selector: 'wtalk-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent {
  @Input() message = ERROR_MESSAGE_URL_IS_BROKEN;
}
