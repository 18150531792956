const ERROR_MESSAGE_TRY_REFRESH_THE_PAGE = 'Try to refresh the page or contact administrator.';
const ERROR_MESSAGE_URL_IS_BROKEN = `Url is broken. ${ERROR_MESSAGE_TRY_REFRESH_THE_PAGE}`;
const ERROR_MESSAGE_EVENT_HAS_FINISHED = 'Event has finished. Please select another time in the calendar.';
const ERROR_MESSAGE_SESSION_ALREADY_IN_PROGRESS = 'The session is already in progress. Let’s meet during the next session.';

export {
  ERROR_MESSAGE_TRY_REFRESH_THE_PAGE,
  ERROR_MESSAGE_URL_IS_BROKEN,
  ERROR_MESSAGE_EVENT_HAS_FINISHED,
  ERROR_MESSAGE_SESSION_ALREADY_IN_PROGRESS
};
