import { Injectable } from '@angular/core';
enum DesktopOS {
  Linux = 'linux',
  MacOS = 'mac_os',
  Unix = 'unix',
  Unknown = 'unknown',
  Windows = 'windows'
}

enum MobileOS {
  Android = 'android',
  iOS = 'ios',
  Unknown = 'unknown',
  WindowsPhone = 'Windows Phone'
}

type DeviceOS = DesktopOS | MobileOS

export interface DeviceState {
  isDesktop: boolean
  desktopOS: DesktopOS | undefined
  isWindowsDesktop: boolean
  isLinuxOrUnixDesktop: boolean

  isMobile: boolean
  mobileOS: MobileOS | undefined
  isAndroidDevice: boolean
  isAppleDevice: boolean
  isUnknownMobileDevice: boolean

  isTablet: boolean
  isLandscapeOrientation: () => boolean
  isPortraitOrientation: () => boolean
}

@Injectable()
export class PlatformDetectorService {
  userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera || undefined;
  screenOrientation: OrientationType | any;
  constructor() {
    if (screen.orientation) {
      const initialScreenOrientation = this.supportedScreenOrientation() ?? this.safariScreenOrientation() ?? 'portrait-primary';
      this.screenOrientation = initialScreenOrientation;
      screen.orientation.addEventListener(
        'change',
        (ev: Event) => (this.screenOrientation = (ev.target ?? ({} as any)).type)
      )
    }
  }

  isMobileDevice(): boolean {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
    return regexs.some((b) => this.userAgent.match(b));
  }

  isTabletDevice(): boolean {
    const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
    return regex.test(this.userAgent.toLowerCase());
  }

  isDesktopDevice (): boolean {
    return !this.isMobileDevice() && !this.isTabletDevice();
  }

  getMobileOS(): MobileOS | undefined {
    if (this.isMobileDevice()) {
      if (/windows phone/i.test(this.userAgent)) return MobileOS.WindowsPhone
      else if (/android/i.test(this.userAgent)) return MobileOS.Android
      else if (/iPad|iPhone|iPod/.test(this.userAgent) && !(window as any).MSStream) return MobileOS.iOS

      return MobileOS.Unknown
    } else return undefined
  }

  getDesktopOS(): DesktopOS | undefined {
    if (this.isDesktopDevice()) {
      if (this.userAgent.indexOf('Win') !== -1) return DesktopOS.Windows
      else if (this.userAgent.indexOf('Mac') !== -1) return DesktopOS.MacOS
      else if (this.userAgent.indexOf('X11') !== -1) return DesktopOS.Unix
      else if (this.userAgent.indexOf('Linux') !== -1) return DesktopOS.Linux

      return DesktopOS.Unknown
    } else return undefined;
  }

  isAndroidDevice(): boolean {
    return this.getDeviceOS() === MobileOS.Android;
  }

  isAppleDevice(): boolean{
    return this.getDeviceOS() === MobileOS.iOS || this.getDeviceOS() === DesktopOS.MacOS;
  }

  isUnknownMobileDevice(): boolean {
    return this.getDeviceOS() === MobileOS.Unknown;
  }

  isWindowsDesktop(): boolean {
    return this.getDeviceOS() === DesktopOS.Windows;
  }
  isLinuxOrUnixDesktop(): boolean {
    return this.getDeviceOS() === DesktopOS.Linux || this.getDeviceOS() === DesktopOS.Unix;
  }

  getDeviceOS(): DeviceOS | undefined {
    return this.getMobileOS() ?? this.getDesktopOS();
  }

  supportedScreenOrientation(): OrientationType {
    return (screen?.orientation || {}).type ?? (screen as any).mozOrientation ?? (screen as any).msOrientation
  }

  safariScreenOrientation(): OrientationType {
    return !screen?.orientation && matchMedia('(orientation: portrait)').matches ? 'portrait-primary' : 'landscape-primary';
  }

  get initialScreenOrientation() {
    return this.supportedScreenOrientation ?? this.safariScreenOrientation ?? 'portrait-primary';
  }

  isLandscapeOrientation(): boolean {
    return ['landscape-primary', 'landscape-secondary'].includes(this.screenOrientation);
  }

  isPortraitOrientation(): boolean {
    return ['portrait-primary', 'portrait-secondary'].includes(this.screenOrientation);
  }

}
