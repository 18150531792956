export const environment = {
  production: false,
  envName: 'production',
  contentUrl: 'https://wtalk.space',
  apiUrl: 'https://api.wtalk.space/api',
  googleApiKey: 'AIzaSyCaqU04kDvAv46CLR55eD9si_QFJDh5NcM',
  waitingRoomUrl: 'https://wtalk.whereby.com/waiting-room87ba4e50-58a9-418a-a5b8-f07173f62062',
  firebase: {
    apiKey: 'AIzaSyDI5uXgli5txygi3CJieFuWMZ7MqHFyKAg',
    authDomain: 'wtalk-bda14.firebaseapp.com',
    databaseURL: 'https://wtalk-bda14-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'wtalk-bda14',
    storageBucket: 'wtalk-bda14.appspot.com',
    messagingSenderId: '214738147332',
    appId: '1:214738147332:web:3903823e337d54b94c7d51',
    measurementId: 'G-ZJ21Z0NNNL'
  },
  supabaseUrl: 'https://zcyunqjphuxvfewswqyj.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpjeXVucWpwaHV4dmZld3N3cXlqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzcxNjU1ODQsImV4cCI6MTk5Mjc0MTU4NH0.k4m_kftJKFrvp9R2war-3ri_k7ZqtyeUwaZOBy-_Ys8',
  sentryDsn: 'https://8cb495b0bd4b473d829300b809cf6766@sentry.trembit.com/6',
  hotjar: {
    siteId: 3732032,
    version: 6
  }
};
