import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RegisterIconsService } from './core/services/register-icons/register-icons.service';
import { PlatformDetectorService } from './core/services/platform-detector/platform-detector.service';

@Component({
  selector: 'wtalk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [RegisterIconsService, PlatformDetectorService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  constructor(private readonly registerIconsService: RegisterIconsService) {
    this.registerIconsService.register();
  }
}
