import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Hotjar from '@hotjar/browser';

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.envName,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['wtalk-prod'],
        routingInstrumentation: Sentry.routingInstrumentation,
        idleTimeout: 10000,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const hotjar: any = environment.hotjar;
if(hotjar){
  Hotjar.init(hotjar.siteId, hotjar.version);
}

enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
