import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class RegisterIconsService {
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

  public register(): void {
    this.matIconRegistry.addSvgIcon('lamp', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/lamp.svg'));
    this.matIconRegistry.addSvgIcon('close-dialog', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close-dialog.svg'));
  }
}
