import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorMessageComponent } from './error-message/error-message.component';

const routes: Routes = [
  {
    path: 'event/:eventId',
    loadChildren: () => import('./video-event/video-event.module').then(m => m.VideoEventModule),
    pathMatch: 'full'
  },
  {
    path: 'page-not-found',
    component: ErrorMessageComponent
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
